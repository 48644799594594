@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

$font-1: 'Montserrat';
$font-2: 'Roboto Slab';

html, body, #app, .menu-root {
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

.customer-is-captured {
    position: fixed;
    top: 10px;
    left: 10px;
    i {
        font-size: xx-large;
        color: green;
    }
}

.main-drawer-modal {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: blue;

    .main-drawer-body {
        overflow-y: scroll;
        height: 100%;
        max-height: 100%;
        padding-bottom: 40px;

        a, a:hover, a:active, a:visited, a:focus {
            text-decoration:none;
        }

        .separator {
            border-top: 2px solid #fff;
        }

        .drawer-social-media {
            text-align: center;

            .social-wrapper {
                border-radius: 100%;
                width: 50px;
                height: 50px;
                background: #fff;
                text-align: center;
                line-height: 50px;
                padding-top: 7px;
                display: inline-block;
            }

            i {
                font-size: xx-large;
                color: #000;
            }
        }

        .drawer-navigation-item {
            display: block;
            text-align: center;
            font-size: large;
            font-weight: bold;
            padding: 10px 0;
        }
    }
}

.display--main-drawer-icon,
.display--main-drawer-close-icon {
    position: fixed;
    top: 7px;
    right: 14px;

    a {
        color: #fff;
        font-size: 30px;
    }
}

.display--main-drawer-close-icon {
    right: 30px;
}

.display--logo {
    width: 80%;
    margin: 0 auto;

    img {
        max-width: 100%;
    }
}

.menu-root {
    font-family: $font-1;

    h1, h2, h3, h4, h5 {
        padding: 0;
        margin: 0;
    }

    .display--entry-text {
        background: #ecde0b;
        color: #000;
        border-radius: 3px;
        font-weight: bold;
    }

    .category-section {
        .category-section-heading {
            text-align: center;

            h1, h2, h3, h4, h5 {
                text-transform: uppercase;
                margin: 0;
                padding: 0;
            }
        }

        .category-section-description {
            padding: 0;
            margin: 0;
        }

        &:last-child() {
            padding-bottom: 0;
        }
    }

    .category-product {
        .___right {
            text-align: right
        }
    }
}

.screen-top-bar {
    background: grey;
    color: #fff;

    a {
        color: #fff;
        font-weight: normal;
    }

    font-weight: bold;
}

/* CHANUPA  */
.styling__chanupa {
    background: #000;
    color: #fff;

    .separator {
        border-top: 2px solid rgba(255, 255, 255, .3);
    }

    .main-drawer-modal {
        background: #343433;

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #6d6d6d;
                color: #fff;
                font-family: $font-2;
            }
        }

        .category-product {
            &:nth-child(even) {
                background: #343432;
            }

            &:nth-child(odd) {
                background: #211f1e;
            }
        }
    }
}

/* SAX BAR  */
.styling__saxbar {
background: rgb(245,155,172);
background: linear-gradient(90deg, rgba(245,155,172,1) 0%, rgba(0,179,125,1) 60%, rgba(0,189,217,1) 100%);
    .separator {
        border-top: 2px solid rgba(255, 255, 255, .3);
    }

    .main-drawer-modal {
background: rgb(245,155,172);
background: linear-gradient(315deg, rgba(245,155,172,1) 21%, rgba(0,179,125,1) 46%, rgba(0,189,217,1) 100%);

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(245,155,172);
background: linear-gradient(230deg, rgba(245,155,172,1) 21%, rgba(0,179,125,1) 46%, rgba(0,189,217,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            &:nth-child(even) {
                background: rgba(255, 255, 255, .3);
            }

            &:nth-child(odd) {
                background: rgba(255, 255, 255, .5);
            }
        }
    }
}

/* Riverside */
.styling__riverside {
background: rgb(35,106,110);
background: linear-gradient(90deg, rgba(35,106,110,1) 0%, rgba(39,135,140,1) 100%);
    color: #fff;

    .separator {
        border-top: 2px solid rgba(255, 255, 255, .3);
    }

    .main-drawer-modal {
        background: rgb(35,106,110);
        background: linear-gradient(0deg, rgba(35,106,110,1) 0%, rgba(39,135,140,1) 100%);

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #236a6e;
                color: #e3c58a;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            color: #236a6e;

            &:nth-child(even) {
                background: #d1dfe0;
            }

            &:nth-child(odd) {
                background: #a7c2c4;
            }
        }
    }
}

/* Room & Time */
.styling__roomandtime {
    background: #000;
    color: #fff;

    .separator {
        border-top: 2px solid rgba(255, 255, 255, .3);
    }

    .main-drawer-modal {
        background: #000;

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #d6b46c;
                color: #000;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            color: #000;

            &:nth-child(even) {
                background: #e8c272;
            }

            &:nth-child(odd) {
                background: #eebd57;
            }
        }
    }
}

/* Cohibar */
.styling__cohibar {
    background: #000;
    color: #fff;

    .separator {
        border-top: 2px solid rgba(255, 255, 255, .3);
    }

    .main-drawer-modal {
        background: rgb(116,0,1);
        background: linear-gradient(107deg, rgba(116,0,1,1) 0%, rgba(188,170,46,1) 100%);

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: rgb(116,0,1);
                background: linear-gradient(90deg, rgba(116,0,1,1) 0%, rgba(188,170,46,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            color: #000;

            &:nth-child(even) {
                background: #e8c272;
            }

            &:nth-child(odd) {
                background: #eebd57;
            }
        }
    }
}

/* Hypnotize */
.styling__hypnotize, .styling__kaffedekeyff, .styling__avenue285, .styling__mocca, .styling__avenue95 {
    .display--main-drawer-icon, .display--main-drawer-icon * {
        color: #000;
    }

    .main-drawer-modal {
        background: #fff;


        .display--main-drawer-icon,
        .display--main-drawer-close-icon {
            a {
                color: #000;
            }
        }


        .drawer-navigation-item {
            color: #000;
        }

        .separator {
            border-top: 2px solid #000 !important;
        }
    }
}

.styling__sosyete {
    background: #dbdbdb;
    color: #fff;

    .separator {
        border-top: 2px solid #ec1c24;
    }

    .main-drawer-modal {
        background: #dbdbdb;

        .drawer-navigation-item {
            color: #ec1c24;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #ec1c24;
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #000;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .7)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .9)
            }
        }
    }
}

.styling__smokyterrace {
    background: url(../images/smokyterrace/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #fbd252;
    }

    .main-drawer-modal {
        background: url(../images/smokyterrace/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #fbd252;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(198,160,59);
background: linear-gradient(107deg, rgba(198,160,59,1) 0%, rgba(251,210,82,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #fbd252;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .7)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .9)
            }
        }
    }
}

.styling__marcopolo {
    background: url(../images/marco-polo/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #fff;
    }

    .main-drawer-modal {
        background: url(../images/marco-polo/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(134,90,60);
background: linear-gradient(90deg, rgba(134,90,60,1) 0%, rgba(86,55,39,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #fff;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .4)
            }
        }
    }
}

.styling__grace {
    background: url(../images/grace/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #fbd252;
    }

    .main-drawer-modal {
        background: url(../images/grace/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #fbd252;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(198,160,59);
background: linear-gradient(107deg, rgba(198,160,59,1) 0%, rgba(251,210,82,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #fbd252;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .7)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .9)
            }
        }
    }
}

/** LAVIE */
.styling__lavie {
    background: url(../images/la-vie/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #e2e2e2;
    }

    .main-drawer-modal {
        background: url(../images/la-vie/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #e2e2e2;
        }
    }

    .menu-root {
        .category-section {
//            .category-section-heading {
//background: rgb(55,55,55);
//background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(94,94,94,1) 32%);
//                color: #fff;
//                font-family: 'Bebas Neue';
//                text-shadow: 0px 1px 1px #856d2b;
//            }
        }

        .category-product {
            color: #e2e2e2;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

/** Cruz */
.styling__cruz {
    background: url(../images/la-vie/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #e2e2e2;
    }

    .main-drawer-modal {
        background: url(../images/la-vie/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #e2e2e2;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(55,55,55);
background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(94,94,94,1) 32%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #e2e2e2;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

/** B7 Hamburg */
.styling__b7hamburg {
    background: #000;
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #bc9d3b;
    }

    .main-drawer-modal {
        background: #000;
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #bc9d3b;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(55,55,55);
background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(94,94,94,1) 32%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #e2e2e2;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

.slide-leave-active,
.slide-enter-active {
  transition: .2s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: .2s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(-100%, 0);
}


/** Harmony */
.styling__saunaclubharmony {
    background: url(../images/la-vie/bgpattern.jpg);
    background-size: no-repeat;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #e2e2e2;
    }

    .main-drawer-modal {
        background: url(../images/la-vie/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #e2e2e2;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(55,55,55);
background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(94,94,94,1) 32%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #e2e2e2;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

/** Limalima */
.styling__limalima {
    background: rgb(55,55,55);
    background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(43,43,40,1) 32%);
    background-size: no-repeat;
    background-size: cover;
    background-attachment:fixed;
    color: #2b2b28;

    .separator {
        border-top: 2px solid #e2e2e2;
    }

    .main-drawer-modal {
        background: rgb(55,55,55);
        background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(43,43,40,1) 32%);
        background-size: no-repeat;
        background-size: cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #7cad44;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
background: rgb(55,55,55);
background: linear-gradient(90deg, rgba(55,55,55,1) 0%, rgba(94,94,94,1) 32%);
                color: #7cad44;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            color: #fff;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

.styling__dubainights {
    background: #000;
    color: #947c77;

    .separator {
        border-top: 2px solid #947c77;
    }

    .main-drawer-modal {
        background: #000;
        color: #947c77;
        background-size: no-repeat;
        background-size: cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #613605;
                color: #fff;
                font-family: 'Bebas Neue';
            }
        }

        .category-product {
            color: #fff;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .3)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .5)
            }
        }
    }
}

.styling__adailounge {
    background: url(../images/adailounge/bgpattern.jpg);
    background-size: no-repeat;
    background-position: center center;
    background-size:cover;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #fbd252;
    }

    .main-drawer-modal {
        background: url(../images/adailounge/bgpattern.jpg);
        background-size: no-repeat;
        background-size:cover;
        background-attachment:fixed;

        .drawer-navigation-item {
            color: #fff;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: rgb(103,81,66);
                background: linear-gradient(90deg, rgba(103,81,66,1) 0%, rgba(133,102,81,1) 100%);
                color: #fff;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #fff;

            &:nth-child(even) {
                background: rgba(0, 0, 0, .7)
            }

            &:nth-child(odd) {
                background: rgba(0, 0, 0, .9)
            }
        }
    }
}

.styling__embarbier {
    background: #d7c094;
    background-attachment:fixed;
    color: #fff;

    .separator {
        border-top: 2px solid #fbd252;
    }

    .main-drawer-modal {
        background: #d7c094;

        .drawer-navigation-item {
            color: #212121;
        }
    }

    .menu-root {
        .category-section {
            .category-section-heading {
                background: #d7c094;
                color: #212121;
                font-family: 'Bebas Neue';
                text-shadow: 0px 1px 1px #856d2b;
            }
        }

        .category-product {
            color: #212121;
        }
    }
}

.product-additives {
  font-size: xx-small;
  position: relative;
  top: -5px;
}

.product-additives-list {
  font-size: xx-small;
  padding: 2rem;
  text-align: center;
}
